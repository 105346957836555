$light-orange: #ff745f;
$white: #fff9f2;
$crimson: #570a13;
$green: #679283;
$light-gray: #626367;
$beige: #f2e7d9;
$header: #ff745f;
$header-height: 80px;

$header-font: 'Yanone Kaffeesatz', sans-serif;
$body-font: 'Open Sans', sans-serif;

.header-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 60px;
  background-color: $crimson;
  h3 {
    font-size: 30px;
    margin: 0px;
  }
  h4 {
    font-size: 18px;
    margin: 0px;
  }
}

.light-orange {
  color: $light-orange;
}

.light-gray {
  color: $light-gray;
}

.light-gray-bg {
  background-color: $beige;
}

.gray-blue-bg {
  background-color: $green;
}

.white {
  color: $white;
}

.orange {
  color: $crimson;
}

h1 .white {
  margin-top: 40px;
}

.left,
.right {
  width: 50%;
}

#info-footer .left {
  border-right: 1px dashed $light-gray;
}

#info-footer {
  display: flex;
  flex-direction: row;
  height: 50px;
}
#info-footer .left,
#info-footer .right {
  padding: 8px;
}

ul {
  padding-left: 20px;
}

.image-placeholder {
  margin: auto;
  height: 150px;
  width: 150px;
}

.image-text {
  padding-top: 20px;
  text-align: center;
}

// page container
body {
  margin: 0;
  background-color: $white;
  font-family: $body-font;
  #contents {
    max-width: 1200px;
    margin: $header-height auto 0;
  }
}

// page header
h1 {
  font-size: 50px;
  margin: 0;
  font-family: $header-font;
}

.header {
  background-color: $light-orange;
  padding: 120px 30px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  line-height: 1;
  .white {
    color: $white;
    font-weight: 400;
    font-size: 90px;
  }
  .orange {
    color: $crimson;
    font-weight: 700;
    font-size: 106px;
  }
  .small-header {
    font-size: 75px;
  }
}

#chinese-text {
  width: 370px;
  margin-bottom: 10px;
}

//info section

#information {
  display: flex;
  flex-direction: row;
  padding: 30px;
  color: $light-gray;
  h3 {
    font-family: $header-font;
    text-transform: uppercase;
    font-size: 30px;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .bullet {
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
}

#information .left,
#information .right {
  padding: 30px;
}

#information .left {
  border-right: 1px dashed $white;
}

#information .right {
  padding-left: 30px;
}

.bullet {
  background-color: $light-orange;
  color: $crimson;
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  line-height: 30px;
}

.bullet-title {
  display: inline-block;
  font-family: $header-font;
  text-transform: capitalize;
  font-size: 25px;
}

// sponsors
#thank-you {
  text-align: center;
  padding: 30px 30px 100px;
  background-color: $green;
  color: $white;
  .sponsors-header {
    font-family: $header-font;
    font-size: 30px;
  }
}

.sponsors-header {
  font-size: 20px;
  padding-top: 30px;
}

.sponsors-text {
  padding: 20px 0;
}
.sponsors-images {
  padding-top: 20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// keep this at the end
.hide {
  display: none !important;
}

//images

.images {
  display: 'flex';
  width: '100%';
  justify-content: center;
  align-items: center;
}

.image {
  flex: 1;
  width: 33%;
}
.map {
  width: 100%;
}
figcaption {
  font-size: 14px;
}

// map detail
.map-detail {
  padding: 40px 80px;
}

// text pages
#main {
  background-color: $beige;
  min-height: 100vh;
}
.text-background {
  padding: 40px 60px;
  a {
    color: $crimson;
  }
}

.text-page {
  background-color: $white;
  padding: 30px 50px;
  margin-top: -80px;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(98, 99, 103, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(98, 99, 103, 1);
  box-shadow: 1px 1px 3px 0px rgba(98, 99, 103, 1);
}

//header nav
#nav {
  background-color: $light-orange;
  width: 100%;
  max-width: 1200px;
  height: $header-height;
  position: fixed;
  top: 0;
  -webkit-box-shadow: 0px 4px 3px -3px rgba(87, 10, 19, 1);
  -moz-box-shadow: 0px 4px 3px -3px rgba(87, 10, 19, 1);
  box-shadow: 0px 4px 3px -3px rgba(87, 10, 19, 1);
  display: flex;
  align-items: center;
  a {
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    margin-left: 25px;
    display: block;
    color: $crimson;
  }
}

.iframe-container {
  height: 85vh;
  padding-bottom: 30px;
  background-color: $beige;
}
